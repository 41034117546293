
import Vue from 'vue';
import { MetaInfo, ScriptPropertySrc } from 'vue-meta';

import { EColor } from '@/domain/core/Color.enum';
import { EContentType } from '@/domain/core/ContentType.enum';
import { EAppEnvironment } from '@/domain/core/Environment.enum';
import { AllCookies, ECookie } from '@/domain/core/http/Cookie.enum';
import { EMaxAge } from '@/domain/core/http/MaxAge.enum';
import { EMetaTags } from '@/domain/core/Meta.enum';
import { ERouteName } from '@/domain/core/Routes.enum';
import { CurrencyCode } from '@/domain/pricing/types';
import { EGtmEventInit } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { EFeatureFlags } from '@/infrastructure/externalServices/launchDarkly/types';
import { State as UserState } from '@/store/user';
import { _insertScript } from '@/utilities/DOM/insertScript';

export default Vue.extend({
  name: 'DefaultLayout',
  data() {
    return {
      isModalAppDownloadPromptOpen: false,
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      const { userCartId: cartId, userId } = this.$cookies.getAll<AllCookies>();
      const requests = [
        this.$accessor.notifications.fetchNotificationsCount({ cartId, userId }),
      ];

      if (this.isUserAuthenticated) {
        requests.push(
          this.$accessor.shop.fetchShopStatus(),
          this.$accessor.wishlist.fetchWishlist(),
        );
      }

      if (this.isUserAdmin) {
        requests.push(
          this.$accessor.selection.fetchTriageContent(),
        );
      }

      await Promise.allSettled(requests);
    } catch (err) {
      this.$errorMonitor.report(err, 'fatal');
    }
  },
  head(): MetaInfo {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      style: [{
        pbody: true,
        cssText: this.rootStyles,
        type: EContentType.Css,
      }],
      script: [this.polyfillsScript],
      meta: [{
        name: EMetaTags.ThemeColor,
        content: this.$accessor.ui.accentColor.color,
      }],
    };
  },
  computed: {
    currencyCode(): CurrencyCode {
      return this.$accessor.pricing.selectedCurrencyCode;
    },
    user(): UserState {
      return this.$accessor.user;
    },
    isBackdropVisible(): boolean {
      return this.$accessor.ui.backdrop.isVisible;
    },
    isUserAdmin(): boolean {
      return this.$accessor.user.isAdmin;
    },
    isUserAuthenticated(): boolean {
      return this.$accessor.user.authenticated;
    },
    isCheckoutFunnel(): boolean {
      const [parentRouteName] = this.$route?.matched || [];

      return parentRouteName?.name === ERouteName.Checkout;
    },
    polyfillsScript(): ScriptPropertySrc {
      const baseURL = 'https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js';
      const features = [
        'Intl.NumberFormat',
        `Intl.NumberFormat.~locale.${this.$i18n.locale}`,
        `Intl.PluralRules.~locale.${this.$i18n.locale}`,
        'ResizeObserver',
      ];
      const queryParams = new URLSearchParams({ features: features.join(',') });

      return {
        defer: true,
        hid: 'polyfills',
        src: `${baseURL}?${queryParams.toString()}`,
        type: EContentType.Javascript,
      };
    },
    rootStyles(): string {
      const colors = {
        [EColor.Accent]: this.user.isPro ? `var(${EColor.Pro})` : this.$accessor.ui.getAccentColor,
        [EColor.AccentFont]: this.user.isPro ? `var(${EColor.White})` : this.$accessor.ui.getAccentFontColor,
      };
      const styles = Object.entries(colors)
        .map(([variableName, value]) => `${variableName}:${value};`)
        .join('');

      return `:root{${styles}}`;
    },
    shouldDisplayNavigationStepsCheckout(): boolean {
      return this.$route.name !== ERouteName.CheckoutSuccess;
    },
    shouldDisplayModalAppDownloadPrompt(): boolean {
      return this.$device.isMobile && this.isModalAppDownloadPromptOpen;
    },
  },
  mounted() {
    this.$gtm.push({
      event: EGtmEventInit.DLInit,
      currency: this.currencyCode,
      geoloc_country: null,
      geoloc_zipcode: null,
      user_id: this.user?.id,
      user_type: this.user?.roles,
      user_is_pro: this.user?.isPro,
      user_email_hashed: this.user?.hashedUser,
      shop_id: this.user?.shopId || null,
      user_ab_tests: this.$cookies?.get(ECookie.UserAbTests) || null,
    });

    const jsSdkLoadingDelayInMs = 4000;
    const recaptchaInitDelayInMs = 1000;

    this.openModalAppDownloadPromptIfNeeded();
    this.setCsrfTokenIfNeeded();
    this.setUserSegmentIdIfNeeded(); // Each device accessing the webapp is assigned a segment ID at first connection for Algolia A/B tests

    setTimeout(() => {
      this.initGoogleRecaptcha();
    }, recaptchaInitDelayInMs);

    if (this.$device.isDesktop) { // NOTE: 🤡 - https://linear.app/selency/issue/SEL-940
      setTimeout(() => {
        this.loadSdkCookieBanner();
      }, jsSdkLoadingDelayInMs);
    }

    if (this.$config.appEnvironment === EAppEnvironment.Production) {
      this.loadSdkHotjar();

      setTimeout(() => {
        this.loadSdkBotmind();
      }, jsSdkLoadingDelayInMs);
    }
  },
  beforeDestroy() {
    this.$recaptcha.destroy();
  },
  methods: {
    openModalAppDownloadPromptIfNeeded(): void {
      if (this.$accessor?.featureFlags?.list?.[EFeatureFlags.CrmModalAppDownloadPromptForMobileOnly]) {
        const appDownloadPromptDelayInMs = 3000;

        // NOTE: delay to avoid CLS issues.
        setTimeout(() => {
          this.isModalAppDownloadPromptOpen = this.$cookies.get(ECookie.AppDownloadPromptDismissed) === undefined;
        }, appDownloadPromptDelayInMs);
      }
    },
    dismissModalAppDownloadPrompt(): void {
      this.isModalAppDownloadPromptOpen = false;

      this.$cookies.set(ECookie.AppDownloadPromptDismissed, true, {
        maxAge: EMaxAge.OneHour,
        path: '/',
        sameSite: 'lax',
      });
    },
    async initGoogleRecaptcha(): Promise<void> {
      try {
        await this.$recaptcha.init();
      } catch {} // NOTE: logging Recaptcha error just floods Sentry. 💡
    },
    loadSdkBotmind(): void {
      _insertScript({
        id: 'botmind-jssdk',
        src: '/botmind.js',
      });
    },
    loadSdkCookieBanner(): void {
      _insertScript({
        id: 'cookie-banner-jssdk',
        src: 'https://app.termly.io/resource-blocker/2add1790-ef36-42b6-b942-b9d404a7bbb8?autoBlock=off',
      });
    },
    loadSdkHotjar(): void {
      const domain = window.location.host;
      const siteIdByDomain = {
        [this.$config.domains.FR as string]: this.$config.hotjarDomainSiteId.FR,
        [this.$config.domains.GB as string]: this.$config.hotjarDomainSiteId.GB,
        [this.$config.domains.NL as string]: this.$config.hotjarDomainSiteId.NL,
      };

      const siteId = domain ? siteIdByDomain[domain] : null;

      if (siteId) {
        // NOTE: enhanced Hotjar's snippet.
        window.hj = window.hj || function() { (window.hj.q = window.hj.q || []).push(arguments); };
        window._hjSettings = {
          hjid: siteId,
          hjsv: 6,
        };

        _insertScript({
          // @ts-ignore
          async: '', // NOTE: attribute needed but empty.
          id: 'hotjar-jssdk',
          src: `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`,
        }, true);
      }
    },
    setCsrfTokenIfNeeded(): void {
      if (this.$cookies.get(ECookie.CsrfToken) === undefined) {
        this.$cookies.set(ECookie.CsrfToken, `${Date.now()}`, {
          maxAge: EMaxAge.SixMonths,
          path: '/',
          sameSite: true,
          secure: true,
          httpOnly: false,
        });
      }
    },
    setUserSegmentIdIfNeeded(): void {
      if (this.$cookies.get(ECookie.UserSegmentId) === undefined) {
        const segmentId = 'segmentid-' + ((Math.random() + '').slice(2));

        this.$cookies.set(ECookie.UserSegmentId, segmentId, {
          maxAge: EMaxAge.OneMonth,
          path: '/',
          sameSite: true,
          secure: true,
          httpOnly: false,
        });
      }
    },
  },
});
